function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export var colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

var diffInPercentage = function diffInPercentage(oldValue, newValue) {
  if (!oldValue && !newValue) {
    return React.createElement(
      'span',
      { style: { color: 'gray' } },
      '0'
    );
  } else if (!oldValue && newValue) {
    return React.createElement(
      'span',
      { style: { color: 'green' } },
      '+\u221E%'
    );
  } else if (oldValue && !newValue) {
    return React.createElement(
      'span',
      { style: { color: 'red' } },
      '-100%'
    );
  } else if (oldValue > newValue) {
    return React.createElement(
      'span',
      { style: { color: 'red' } },
      '-',
      Math.round((oldValue - newValue) / oldValue * 100),
      '%'
    );
  } else if (oldValue < newValue) {
    return React.createElement(
      'span',
      { style: { color: 'green' } },
      '+',
      Math.round((newValue - oldValue) / oldValue * 100),
      '%'
    );
  } else {
    return React.createElement(
      'span',
      { style: { color: 'gray' } },
      '0%'
    );
  }
};

var diffInCount = function diffInCount(oldValue, newValue, localeStringOptions) {
  if (!oldValue && !newValue || oldValue === newValue) {
    return React.createElement(
      'span',
      { style: { color: 'gray' } },
      '0'
    );
  } else if (oldValue < newValue) {
    return React.createElement(
      'span',
      { style: { color: 'green' } },
      '+',
      Math.round(newValue - oldValue).toLocaleString('en', localeStringOptions)
    );
  } else if (oldValue > newValue) {
    return React.createElement(
      'span',
      { style: { color: 'red' } },
      '-',
      Math.round(oldValue - newValue).toLocaleString('en', localeStringOptions)
    );
  }
};

var BasicTooltip = function (_Component) {
  _inherits(BasicTooltip, _Component);

  function BasicTooltip() {
    _classCallCheck(this, BasicTooltip);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  BasicTooltip.prototype.labelFormatter = function labelFormatter(time) {
    var timeGroup = this.props.graph.timeGroup;


    if (timeGroup === 'year') {
      return moment(time).format('YYYY');
    } else if (timeGroup === 'quarter') {
      return 'Q' + moment(time).format('Q YYYY');
    } else if (timeGroup === 'month') {
      return moment(time).format('MMMM YYYY');
    } else if (timeGroup === 'week') {
      var firstDay = moment(time);
      var lastDay = moment(time).add(6, 'days');
      if (firstDay.month() === lastDay.month()) {
        return firstDay.format('DD') + ' - ' + lastDay.format('DD MMMM YYYY');
      } else if (firstDay.year() === lastDay.year()) {
        return firstDay.format('DD MMMM') + ' - ' + lastDay.format('DD MMMM YYYY');
      } else {
        return firstDay.format('DD MMMM YYYY') + ' - ' + lastDay.format('DD MMMM YYYY');
      }
    } else {
      return moment(time).format('dddd, LL');
    }
  };

  BasicTooltip.prototype.render = function render() {
    var _props = this.props,
        active = _props.active,
        payload = _props.payload,
        label = _props.label,
        graph = _props.graph,
        controls = _props.controls;
    var timeGroup = graph.timeGroup,
        keys = graph.keys;
    var compareWith = controls.compareWith,
        percentages = controls.percentages,
        prediction = controls.prediction;

    var facets = graph.facets && graph.facets.length > 0;
    var unit = '';

    var time = moment(label).format('YYYY-MM-DD');
    var showPercentages = percentages && facets;
    var showCompare = !!compareWith && compareWith !== 0 && compareWith !== '0';
    var showPrediction = prediction && moment().startOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).format('YYYY-MM-DD') === time;

    if (active && payload) {
      var visiblePayload = payload.filter(function (item) {
        return item.dataKey.indexOf('__hidden') < 0;
      }).filter(function (i) {
        return i.dataKey.indexOf('compareWith:') < 0;
      });
      var compareWithPayload = showCompare ? payload.filter(function (item) {
        return item.dataKey.indexOf('__hidden') < 0;
      }).filter(function (i) {
        return i.dataKey.indexOf('compareWith::') === 0;
      }) : [];

      var total = visiblePayload.map(function (item) {
        return percentages ? parseFloat(item.payload[item.dataKey.replace('__%', '')] || 0) : item.value - 0;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var totalPercentage = percentages ? visiblePayload.map(function (item) {
        return parseFloat(item.payload[item.dataKey]);
      }).reduce(function (a, b) {
        return a + b;
      }, 0) : 100;
      var percentageFrom = facets ? total : visiblePayload.map(function (p) {
        return p.value;
      }).reduce(function (a, b) {
        return Math.max(a, b);
      }, 0);

      var visibleValues = visiblePayload.map(function (item) {
        return percentages ? item.payload[item.dataKey.replace('__%', '')] : item.value;
      });
      var compareWithValues = compareWithPayload.map(function (item) {
        return percentages ? item.payload[item.dataKey.replace('__%', '')] : item.value;
      });

      var showFloat = visibleValues.concat(compareWithValues).some(function (v) {
        return v && v.toString().includes('.') && !v.toString().match(/\.0+$/);
      });

      var localeStringOptions = { minimumFractionDigits: showFloat ? 2 : 0, maximumFractionDigits: showFloat ? 2 : 0 };

      var compareWithTotal = void 0;
      var compareWithTotalPercentage = void 0;
      var compareWithPercentageFrom = void 0;

      if (showCompare) {
        compareWithTotal = compareWithPayload.map(function (item) {
          return percentages ? parseFloat(item.payload[item.dataKey.replace('__%', '')] || 0) : (item.value || 0) - 0;
        }).reduce(function (a, b) {
          return a + b;
        }, 0);
        compareWithTotalPercentage = percentages ? compareWithPayload.map(function (item) {
          return parseFloat(item.payload[item.dataKey] || 0);
        }).reduce(function (a, b) {
          return a + b;
        }, 0) : 100;
        compareWithPercentageFrom = facets ? compareWithTotal : compareWithPayload.map(function (p) {
          return p.value;
        }).reduce(function (a, b) {
          return Math.max(a, b);
        }, 0);
      }

      var fullTime = showPrediction ? moment().endOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).unix() - moment().startOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).unix() : 1;
      var elapsedTime = showPrediction ? moment().unix() - moment().startOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).unix() : 1;
      var elapsedRatio = fullTime > 0 && elapsedTime > 0 ? elapsedTime / fullTime : 1;

      return React.createElement(
        'div',
        { className: 'recharts-default-tooltip', style: { background: 'white', border: '1px solid #ddd', padding: '10px', boxShadow: '1px 1px 3px rgba(0,0,0,0.24)' } },
        React.createElement(
          'table',
          null,
          React.createElement(
            'thead',
            null,
            React.createElement(
              'tr',
              null,
              React.createElement('th', null),
              showCompare && React.createElement(
                'th',
                null,
                this.labelFormatter(moment(label).subtract(compareWith, timeGroup))
              ),
              showCompare && showPercentages && React.createElement(
                'th',
                null,
                '%'
              ),
              React.createElement(
                'th',
                null,
                this.labelFormatter(label)
              ),
              showPercentages && React.createElement(
                'th',
                null,
                '%'
              ),
              showCompare && React.createElement(
                'th',
                null,
                '\u0394'
              ),
              showCompare && React.createElement(
                'th',
                null,
                '\u0394 %'
              ),
              showPrediction && React.createElement(
                'th',
                null,
                'Prediction'
              ),
              showPrediction && showCompare && React.createElement(
                'th',
                null,
                '\u0394'
              ),
              showPrediction && showCompare && React.createElement(
                'th',
                null,
                '\u0394 %'
              )
            )
          ),
          React.createElement(
            'tbody',
            null,
            facets ? React.createElement(
              'tr',
              null,
              React.createElement(
                'td',
                { style: { paddingRight: 5, paddingBottom: 5 } },
                'Total:'
              ),
              showCompare && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                compareWithTotal.toLocaleString('en', localeStringOptions),
                unit
              ),
              showCompare && showPercentages && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                Math.round(compareWithTotalPercentage),
                '%'
              ),
              React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                total.toLocaleString('en', localeStringOptions),
                unit
              ),
              showPercentages && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                Math.round(totalPercentage),
                '%'
              ),
              showCompare && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                diffInCount(compareWithTotal, total, localeStringOptions)
              ),
              showCompare && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                diffInPercentage(compareWithTotal, total)
              ),
              showPrediction && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                (total / elapsedRatio).toLocaleString('en', localeStringOptions),
                unit
              ),
              showPrediction && showCompare && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                diffInCount(compareWithTotal, total / elapsedRatio, localeStringOptions)
              ),
              showPrediction && showCompare && React.createElement(
                'td',
                { style: { textAlign: 'right' } },
                diffInPercentage(compareWithTotal, total / elapsedRatio)
              )
            ) : null,
            visiblePayload.map(function (item) {
              var value = (percentages ? parseFloat(item.payload[item.dataKey.replace('__%', '')] || 0) : item.value - 0) || 0;
              var displayValue = value.toLocaleString('en', localeStringOptions);
              var percentage = percentages ? item.payload[item.dataKey] || 0 : Math.round(value / percentageFrom * 100);

              var compareWithValue = void 0;
              var compareWithDisplayValue = void 0;
              var compareWithPercentage = void 0;

              var predictionValue = void 0;
              var predictionDisplayValue = void 0;

              if (showCompare) {
                var compareWithItem = compareWithPayload.filter(function (i) {
                  return i.dataKey === 'compareWith::' + item.dataKey;
                })[0];
                if (compareWithItem) {
                  compareWithValue = (percentages ? parseFloat(compareWithItem.payload[compareWithItem.dataKey.replace('__%', '')] || 0) : compareWithItem.value - 0) || 0;
                  compareWithDisplayValue = compareWithValue.toLocaleString('en', localeStringOptions);
                  compareWithPercentage = percentages ? compareWithItem.payload[compareWithItem.dataKey] : compareWithValue ? Math.round(compareWithValue / compareWithPercentageFrom * 100) : 0;
                }
              }

              if (showPrediction) {
                predictionValue = value / elapsedRatio;
                predictionDisplayValue = predictionValue.toLocaleString('en', localeStringOptions);
              }

              var color = item.color;
              var compareColor = showCompare && keys.length === 1 ? colors[1] : color;

              return React.createElement(
                'tr',
                { key: item.dataKey, className: 'recharts-tooltip-item', style: { color: color } },
                React.createElement(
                  'td',
                  { style: { paddingRight: 10 } },
                  item.name,
                  ':'
                ),
                showCompare && React.createElement(
                  'td',
                  { style: { textAlign: 'right', color: compareColor } },
                  compareWithDisplayValue,
                  unit
                ),
                showCompare && showPercentages && React.createElement(
                  'td',
                  { style: { textAlign: 'right', color: compareColor } },
                  Math.round(compareWithPercentage) + '%'
                ),
                React.createElement(
                  'td',
                  { style: { textAlign: 'right' } },
                  displayValue,
                  unit
                ),
                showPercentages && React.createElement(
                  'td',
                  { style: { textAlign: 'right' } },
                  Math.round(percentage) + '%'
                ),
                showCompare && React.createElement(
                  'td',
                  { style: { textAlign: 'right' } },
                  diffInCount(compareWithValue, value, localeStringOptions)
                ),
                showCompare && React.createElement(
                  'td',
                  { style: { textAlign: 'right' } },
                  diffInPercentage(compareWithValue, value)
                ),
                showPrediction && React.createElement(
                  'td',
                  { style: { textAlign: 'right' } },
                  predictionDisplayValue,
                  unit
                ),
                showPrediction && showCompare && React.createElement(
                  'td',
                  { style: { textAlign: 'right' } },
                  diffInCount(compareWithValue, predictionValue, localeStringOptions)
                ),
                showPrediction && showCompare && React.createElement(
                  'td',
                  { style: { textAlign: 'right' } },
                  diffInPercentage(compareWithValue, predictionValue)
                )
              );
            })
          )
        )
      );
    }

    return null;
  };

  return BasicTooltip;
}(Component);

BasicTooltip.propTypes = {
  graph: PropTypes.object,
  controls: PropTypes.object
};
export default BasicTooltip;