var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, ComposedChart, Area, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, Pie, PieChart } from 'recharts';
import moment from 'moment';

import BasicTooltip from './basic-tooltip';

export var colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

function sharedStart(array) {
  var A = array.concat().sort();
  var a1 = A[0];
  var a2 = A[A.length - 1];
  var L = a1.length;
  var i = 0;
  while (i < L && a1.charAt(i) === a2.charAt(i)) {
    i++;
  }
  return a1.substring(0, i);
}

var alphabeticalFacetSorter = function alphabeticalFacetSorter(a, b) {
  return a.name.localeCompare(b.name);
};

function getGraphData(graph, controls) {
  var results = graph.results,
      keys = graph.keys,
      timeGroup = graph.timeGroup;
  var percentages = controls.percentages,
      compareWith = controls.compareWith,
      compareWithPercentageLine = controls.compareWithPercentageLine,
      prediction = controls.prediction;


  if (timeGroup) {
    var graphData = results.map(function (oldRow) {
      var time = oldRow.time;
      var row = Object.assign({}, oldRow, { time: moment(time).valueOf() });

      if (percentages || compareWith) {
        var total = 0;
        for (var _iterator = keys, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
          var _ref;

          if (_isArray) {
            if (_i >= _iterator.length) break;
            _ref = _iterator[_i++];
          } else {
            _i = _iterator.next();
            if (_i.done) break;
            _ref = _i.value;
          }

          var key = _ref;

          total += parseFloat(row[key] || 0);
        }
        if (percentages) {
          for (var _iterator2 = keys, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
            var _ref2;

            if (_isArray2) {
              if (_i2 >= _iterator2.length) break;
              _ref2 = _iterator2[_i2++];
            } else {
              _i2 = _iterator2.next();
              if (_i2.done) break;
              _ref2 = _i2.value;
            }

            var _key = _ref2;

            row[_key + '__%'] = total !== 0 ? parseFloat(row[_key]) / total * 100 : 0;
          }
        }

        if (compareWith) {
          var compareWithTotal = 0;
          for (var _iterator3 = keys, _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[Symbol.iterator]();;) {
            var _ref3;

            if (_isArray3) {
              if (_i3 >= _iterator3.length) break;
              _ref3 = _iterator3[_i3++];
            } else {
              _i3 = _iterator3.next();
              if (_i3.done) break;
              _ref3 = _i3.value;
            }

            var _key2 = _ref3;

            compareWithTotal += parseFloat(row['compareWith::' + _key2] || 0);
          }

          if (percentages) {
            for (var _iterator4 = keys, _isArray4 = Array.isArray(_iterator4), _i4 = 0, _iterator4 = _isArray4 ? _iterator4 : _iterator4[Symbol.iterator]();;) {
              var _ref4;

              if (_isArray4) {
                if (_i4 >= _iterator4.length) break;
                _ref4 = _iterator4[_i4++];
              } else {
                _i4 = _iterator4.next();
                if (_i4.done) break;
                _ref4 = _i4.value;
              }

              var _key3 = _ref4;

              row['compareWith::' + _key3 + '__%'] = compareWithTotal !== 0 ? parseFloat(row['compareWith::' + _key3]) / compareWithTotal * 100 : 0;
            }
          }

          if (compareWithPercentageLine && compareWithTotal !== 0) {
            var totalToCompareWith = compareWithTotal;

            if (prediction) {
              if (moment().startOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).format('YYYY-MM-DD') === time) {
                var fullTime = moment().endOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).unix() - moment().startOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).unix();
                var elapsedTime = moment().unix() - moment().startOf(timeGroup === 'week' ? 'isoWeek' : timeGroup).unix();

                totalToCompareWith = totalToCompareWith * elapsedTime / fullTime;
              }
            }

            row['compareWith:percentageLine'] = (total - totalToCompareWith) / totalToCompareWith * 100;
          }
        }
      }
      return row;
    });

    console.log(graphData);

    return graphData;
  } else {
    // console.log(keys)
    results.map(function (oldRow) {
      var key = Object.keys(oldRow)[0];
      return {
        name: key,
        value: oldRow[key]
      };
    });

    return results;
  }
}

export var Graph = function (_Component) {
  _inherits(Graph, _Component);

  function Graph(props) {
    _classCallCheck(this, Graph);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _initialiseProps.call(_this);

    _this.state = {
      visibility: {},
      graphData: getGraphData(props.graph, props.controls)
    };
    return _this;
  }

  Graph.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (this.props.graph !== nextProps.graph || this.props.controls !== nextProps.controls) {
      this.setState({
        graphData: getGraphData(nextProps.graph, nextProps.controls)
      });
    }
  };

  Graph.prototype.timeFormatter = function timeFormatter(time) {
    return moment(time).format('MMM DD');
  };

  Graph.prototype.yearFormatter = function yearFormatter(time) {
    return moment(time).format('YYYY');
  };

  Graph.prototype.quarterFormatter = function quarterFormatter(time) {
    return 'Q' + moment(time).format('Q YYYY');
  };

  Graph.prototype.shortQuarterFormatter = function shortQuarterFormatter(time) {
    return 'Q' + moment(time).format('Q\'YY');
  };

  Graph.prototype.monthFormatter = function monthFormatter(time) {
    return moment(time).format(moment(time).month() === 0 ? 'YYYY' : 'MMMM');
  };

  Graph.prototype.shortMonthFormatter = function shortMonthFormatter(time) {
    return moment(time).format(moment(time).month() === 0 ? 'YYYY' : 'MMM');
  };

  Graph.prototype.getTicks = function getTicks() {
    // const { data, timeGroup } = this.props
    var timeGroup = this.props.graph.timeGroup;
    var data = this.state.graphData;


    var ticks = [];

    if (data.length === 0) {
      return ticks;
    }

    var timeStart = data[0].time;
    var timeEnd = data[data.length - 1].time;

    var day = 86000 * 1000;

    var unit = 'month';

    if (timeGroup === 'month' || timeGroup === 'year' || timeGroup === 'quarter') {
      unit = timeGroup === 'week' ? 'isoWeek' : timeGroup;
    } else if (timeEnd - timeStart < 32 * day && timeGroup !== 'week') {
      unit = 'day';
    } else if (timeEnd - timeStart < 365 * day) {
      unit = 'isoWeek';
    }

    var time = moment(timeStart).startOf(unit);
    if (time >= timeStart) {
      ticks.push(time.valueOf());
    }
    while (time < timeEnd) {
      time = time.add(1, unit);
      if (time <= timeEnd) {
        ticks.push(time.valueOf());
      }
    }

    var tickFormatter = unit === 'year' ? this.yearFormatter : unit === 'quarter' ? ticks.length > 5 ? this.shortQuarterFormatter : this.quarterFormatter : unit === 'month' ? ticks.length > 13 ? this.shortMonthFormatter : this.monthFormatter : this.timeFormatter;

    return { ticks: ticks, tickFormatter: tickFormatter };
  };

  Graph.prototype.getKeysWithMeta = function getKeysWithMeta() {
    var keys = this.props.graph.keys;
    var visibility = this.state.visibility;

    var i = 0;

    var nameSubString = keys.length === 1 ? keys[0].split('.')[0].length + 1 : sharedStart(keys).length;

    return keys.filter(function (v) {
      return v;
    }).map(function (v) {
      var visible = typeof visibility[v] === 'undefined' || visibility[v];
      var object = {
        key: v,
        name: v.substring(nameSubString).split(/[_!$]/).join(' ') || 'empty',
        visible: visible,
        color: visible ? colors[i] : 'white'
      };
      i += 1;
      return object;
    });
  };

  Graph.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        graph = _props.graph,
        controls = _props.controls,
        TooltipProp = _props.tooltip,
        children = _props.children;
    var graphData = this.state.graphData;
    var sort = controls.sort,
        percentages = controls.percentages,
        type = controls.type,
        labels = controls.labels,
        compareWith = controls.compareWith,
        compareWithPercentageLine = controls.compareWithPercentageLine,
        compareWithPercentageLineDomain = controls.compareWithPercentageLineDomain;


    var nullLineNeeded = false;
    var unit = '';
    var facets = graph.facets && graph.facets.length > 0;
    var timeGroup = graph.timeGroup;


    var keysWithMeta = this.getKeysWithMeta();

    // change the key on visibilty changes so the lines would update
    // changing this triggers the chart to be torn down and re-rendered
    var key = keysWithMeta.map(function (k) {
      return '' + k.visible;
    }).join(',') + ('' + (labels ? '-labels' : '') + (type === 'line' ? '-line' : '') + '-' + sort);

    if (timeGroup) {
      var _getTicks = this.getTicks(),
          ticks = _getTicks.ticks,
          tickFormatter = _getTicks.tickFormatter;

      var xDomain = ['dataMin', 'dataMax'];

      if (type === 'bar' && graphData.length > 0) {
        xDomain = [moment(graphData[0].time).add(-0.5, timeGroup).valueOf(), moment(graphData[graphData.length - 1].time).add(0.5, timeGroup).valueOf()];
      }

      var sortedKeysWithMeta = facets ? sort === 'abc' ? keysWithMeta.sort(alphabeticalFacetSorter) : keysWithMeta.reverse() : keysWithMeta;

      return React.createElement(
        ResponsiveContainer,
        null,
        React.createElement(
          ComposedChart,
          {
            data: graphData,
            key: key,
            margin: { top: 0, right: 10, left: 10, bottom: 0 }
          },
          React.createElement(Legend, {
            verticalAlign: 'top',
            align: 'left',
            height: 25,
            iconSize: 10,
            wrapperStyle: { fontSize: 12, marginRight: -10 },
            onClick: this.handleClick,
            onMouseOver: this.handleMouseEnter,
            onMouseOut: this.handleMouseLeave
          }),
          React.createElement(XAxis, {
            type: 'number',
            dataKey: 'time',
            domain: xDomain,
            tickFormatter: tickFormatter,
            ticks: ticks
          }),
          React.createElement(YAxis, {
            domain: percentages ? [0, 100] : ['auto', 'auto'],
            interval: 0,
            orientation: 'right',
            tickFormatter: percentages ? function (y) {
              return Math.round(y) + '%';
            } : function (y) {
              return '' + y.toLocaleString('en') + unit;
            },
            allowDecimals: false
          }),
          React.createElement(CartesianGrid, null),
          React.createElement(Tooltip, { content: React.createElement(TooltipProp, { graph: graph, controls: controls }) }),
          nullLineNeeded ? React.createElement(ReferenceLine, { y: 0, stroke: 'red', alwaysShow: true }) : null,
          compareWith && sortedKeysWithMeta.map(function (key) {
            return React.createElement(Bar, _this2.getLineData(key, facets, compareWith));
          }),
          sortedKeysWithMeta.map(function (key) {
            return type === 'area' ? React.createElement(Area, _this2.getLineData(key, facets)) : type === 'bar' ? React.createElement(Bar, _this2.getLineData(key, facets)) : React.createElement(Line, _this2.getLineData(key, facets));
          }),
          compareWith && compareWithPercentageLine && React.createElement(Line, this.getCompareWithPercentageLine()),
          compareWith && compareWithPercentageLine && React.createElement(YAxis, {
            yAxisId: 'percentageLine',
            orientation: 'left',
            tickFormatter: function tickFormatter(y) {
              return '' + (y > 0 ? '+' : '') + Math.round(y) + '%';
            },
            domain: compareWithPercentageLineDomain || [function (dataMin) {
              return Math.floor(Math.min(0, dataMin || 0));
            }, function (dataMax) {
              return Math.ceil(Math.max(dataMax || 0, 0) / 25) * 25;
            }]
          }),
          children
        )
      );
    } else {
      return React.createElement(
        PieChart,
        { margin: { top: 0, right: 10, left: 10, bottom: 0 } },
        React.createElement(Pie, {
          dataKey: 'value',
          isAnimationActive: false,
          data: graphData,
          outerRadius: 80,
          fill: '#8884d8',
          label: true
        }),
        React.createElement(Legend, {
          verticalAlign: 'top',
          align: 'left',
          height: 25,
          iconSize: 10,
          wrapperStyle: { fontSize: 12, marginRight: -10 },
          onClick: this.handleClick,
          onMouseOver: this.handleMouseEnter,
          onMouseOut: this.handleMouseLeave
        }),
        React.createElement(Tooltip, { content: React.createElement(TooltipProp, { graph: graph, controls: controls }) })
      );
    }
  };

  return Graph;
}(Component);
Graph.propTypes = {
  graph: PropTypes.object,

  controls: PropTypes.shape({
    type: PropTypes.oneOf(['area', 'bar', 'line']).isRequired,
    sort: PropTypes.oneOf(['123', 'abc']).isRequired,
    cumulative: PropTypes.bool,
    percentages: PropTypes.bool,
    labels: PropTypes.bool,
    compareWith: PropTypes.number,
    compareWithPercentageLine: PropTypes.bool,
    prediction: PropTypes.bool
  }).isRequired
};
Graph.defaultProps = {
  tooltip: BasicTooltip
};

var _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  this.handleClick = function (o) {
    var _extends2;

    var dataKey = o.dataKey;
    var visibility = _this3.state.visibility;


    var key = dataKey.replace(/__hidden$/, '');

    _this3.setState({
      visibility: _extends({}, visibility, (_extends2 = {}, _extends2[key] = typeof visibility[key] === 'undefined' ? false : !visibility[key], _extends2))
    });
  };

  this.getLineData = function (key, stacked) {
    var compareWith = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var _props$graph = _this3.props.graph,
        keys = _props$graph.keys,
        timeGroup = _props$graph.timeGroup;
    var _props$controls = _this3.props.controls,
        percentages = _props$controls.percentages,
        labels = _props$controls.labels,
        type = _props$controls.type;


    var color = compareWith && keys.length === 1 ? colors[1] : key.color;

    var data = {
      key: '' + (compareWith ? 'compareWith::' : '') + key.key + (percentages ? '__%' : '') + (key.visible ? '' : '__hidden'),
      type: 'linear',
      dataKey: '' + (compareWith ? 'compareWith::' : '') + key.key + (percentages ? '__%' : '') + (key.visible ? '' : '__hidden'),
      name: key.name + (compareWith ? ' (' + compareWith + ' ' + timeGroup + (compareWith === 1 ? '' : 's') + ' ago)' : ''),
      stroke: color,
      strokeOpacity: compareWith ? 0.5 : 1,
      strokeWidth: 1,
      fill: color,
      fillOpacity: type === 'bar' ? compareWith ? 0.5 : 0.9 : 0.6,
      legendType: 'circle',
      label: labels ? _this3.renderLabel : false,
      isAnimationActive: false,
      stackId: (compareWith ? 'compareWith::' : '') + (stacked ? key.key.indexOf('$$') > 0 ? key.key.split('$$')[0] : '1' : key.key)
    };

    if (type !== 'bar') {
      data.dot = { r: 2, fill: key.color, fillOpacity: 0.5 };
      data.activeDot = { r: 6 };
    }

    return data;
  };

  this.getCompareWithPercentageLine = function (key) {
    var labels = _this3.props.controls.labels;


    var data = {
      key: 'compareWith:percentageLine',
      type: 'linear',
      dataKey: 'compareWith:percentageLine',
      name: 'old vs new',
      stroke: colors[1],
      strokeOpacity: 1,
      strokeWidth: 2,
      style: {
        filter: 'drop-shadow( 0 0 4px #fff )'
      },
      legendType: 'circle',
      label: labels ? _this3.renderLabel : false,
      isAnimationActive: false,
      stackId: 'compareWith:percentageLine',
      dot: { r: 2, fill: colors[1], fillOpacity: 0.5 },
      activeDot: { r: 6 },
      yAxisId: 'percentageLine'
    };

    return data;
  };

  this.renderLabel = function (props) {
    var percentages = _this3.props.controls.percentages;
    var x = props.x,
        y = props.y,
        stroke = props.stroke,
        value = props.value,
        key = props.key;

    var displayValue = Array.isArray(value) ? Math.round(value[1] - value[0]) : Math.round(parseFloat(value));

    if (percentages && displayValue === 100) {
      return null;
    }

    return React.createElement(
      'g',
      { key: key },
      React.createElement(
        'text',
        {
          x: x,
          y: y,
          dy: -5,
          fill: '#fff',
          stroke: '#fff',
          strokeWidth: 3,
          strokeOpacity: 0.9,
          fontSize: 10,
          textAnchor: 'middle'
        },
        displayValue,
        percentages ? '%' : ''
      ),
      React.createElement(
        'text',
        {
          x: x,
          y: y,
          dy: -5,
          fill: stroke,
          fontSize: 10,
          textAnchor: 'middle'
        },
        displayValue,
        percentages ? '%' : ''
      )
    );
  };
};